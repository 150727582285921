<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="500px"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-card-title> Pergunta </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-textarea
              v-model="form.question"
              solo
              flat
              placeholder="Digite uma pergunta..."
              background-color="grey lighten-4"
              no-resize
              hide-details="auto"
              rows="3"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-chip-group v-model="form.question_type" mandatory>
              <v-chip color="primary" outlined filter value="open">
                Aberta
              </v-chip>
              <v-chip color="primary" outlined filter value="single_option">
                Unica escolha
              </v-chip>
              <v-chip color="primary" outlined filter value="multiple_options">
                Multipla escolha
              </v-chip>
            </v-chip-group>
          </v-col>
        </v-row>
        <template v-if="form.question_type != 'open'">
          <template v-for="(row, index) in form.options">
            <v-row :key="index">
              <v-col>
                <app-text-field
                  @click:append="removeOption(index)"
                  append-icon="mdi-close"
                  v-model="form.options[index]"
                />
              </v-col>
            </v-row>
          </template>
          <v-row v>
            <v-btn text @click="addOption()">Adicionar opção</v-btn>
          </v-row>
        </template>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="dialog = false" text class="mr-2">Cancelar</v-btn>
        <v-btn @click="handleSave()" color="primary">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      baseForm: {
        type: null,
        question: null,
        question_type: null,
        answer: null,
        options: [""],
      },
      isInsert: null,

      form: {},
    };
  },

  created() {
    this.reset();
  },

  methods: {
    open(question) {
      this.reset();
      if (question) {
        this.setQuestion(question);
      }

      this.dialog = true;
    },

    setQuestion(question) {
      this.form = JSON.parse(JSON.stringify(question));
      this.isInsert = false;
    },

    reset() {
      this.isInsert = true;
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    addOption() {
      this.form.options.push("");
    },

    removeOption(index) {
      this.$delete(this.form.options, index);
    },

    handleSave() {
      if (this.isInsert) {
        this.store();
      } else {
        this.update();
      }
    },

    store() {
      this.$emit("store", this.form);
      this.dialog = false;
    },

    update() {
      this.$emit("update", this.form);
      this.dialog = false;
    },
  },
};
</script>

<style></style>
