<template>
  <div>
    <v-row class="mx-2">
      <v-col cols="12" md="8" offset-md="2">
        <v-row class="mt-8">
          <v-col cols="4" class="pa-0">
            <app-text-field v-model="form.name" label="Nome da anamnese" />
          </v-col>
        </v-row>
        <v-row class="mt-10">
          <v-col class="pa-0">
            <draggable
              v-model="form.questions"
              @start="drag = true"
              handle=".handle"
              @end="drag = false"
            >
              <template v-for="(question, index) in form.questions">
                <div :key="index">
                  <template v-if="question.type == 'question'">
                    <v-card class="mb-6">
                      <v-card-text class="d-flex justify-space-between">
                        <div>
                          <span style="font-size: 18px">{{
                            question.question
                          }}</span>
                          <br />
                          <v-radio-group
                            v-if="question.question_type == 'single_option'"
                            column
                            v-model="question.answer"
                          >
                            <v-radio
                              v-for="(option, index) in question.options"
                              :label="option"
                              color="primary"
                              :value="option"
                              :key="index"
                            ></v-radio>
                          </v-radio-group>
                          <div
                            v-if="question.question_type == 'multiple_options'"
                            column
                          >
                            <v-checkbox
                              v-for="(option, index) in question.options"
                              v-model="question.answer"
                              :label="option"
                              color="primary"
                              :value="option"
                              :key="index"
                            ></v-checkbox>
                          </div>
                        </div>
                        <div>
                          <v-btn
                            text
                            icon
                            @click="openAnamneseQuestionForm(question, index)"
                            ><v-icon>mdi-pencil</v-icon></v-btn
                          >
                          <v-btn text icon @click="removeQuestion(index)"
                            ><v-icon>mdi-delete</v-icon></v-btn
                          >
                          <v-btn icon text>
                            <app-icon class="handle"> drag_indicator </app-icon>
                          </v-btn>
                        </div>
                      </v-card-text>
                    </v-card>
                  </template>
                  <template v-if="question.type == 'heading'">
                    <div
                      class="d-flex justify-space-between pr-4"
                      style="widht: 100%"
                    >
                      <div>
                        <h3 class="mt-6 mb-2">{{ question.heading }}</h3>
                      </div>
                      <div>
                        <v-btn
                          text
                          icon
                          @click="openAnamneseHeadingForm(question, index)"
                          ><v-icon>mdi-pencil</v-icon></v-btn
                        >
                        <v-btn text icon @click="removeQuestion(index)"
                          ><v-icon>mdi-delete</v-icon></v-btn
                        >
                        <v-btn icon text>
                          <app-icon class="handle"> drag_indicator </app-icon>
                        </v-btn>
                      </div>
                    </div>
                  </template>
                </div>
              </template>
            </draggable>
            <div class="d-flex row">
              <v-btn
                class="mt-4 col-12 mr-4 col-md-3"
                @click="openAnamneseQuestionForm()"
                color="primary"
                outlined
                >Adicionar Pergunta</v-btn
              >
              <v-btn
                class="mt-4 col-12 col-md-3"
                @click="openAnamneseHeadingForm()"
                color="primary"
                outlined
                >Adicionar SubTítulo</v-btn
              >
              <v-btn
                class="mt-4 col-12 ml-auto col-md-2"
                @click="handleSave()"
                color="primary"
                >Salvar</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <AnamneseQuestionForm
      ref="AnamneseQuestionForm"
      @update="updateQuestion($event)"
      @store="addQuestion($event)"
    />
    <AnamneseHeadingForm
      @update="updateHeading($event)"
      @store="addHeading($event)"
      ref="AnamneseHeadingForm"
    />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import AnamneseQuestionForm from "@/components/anamnese/forms/AnamneseQuestionForm";
import AnamneseHeadingForm from "@/components/anamnese/forms/AnamneseHeadingForm";

export default {
  components: {
    draggable,
    AnamneseQuestionForm,
    AnamneseHeadingForm,
  },
  data() {
    return {
      indexHeading: null,
      editIndex: null,
      form: {},
      baseForm: {
        name: null,
        questions: [],
      },
    };
  },
  computed: {
    isInsert() {
      return !this.form.id;
    },
  },

  created() {
    this.open(this.$route.params.id);
  },

  methods: {
    open(id = null) {
      this.reset();
      if (id > 0) {
        this.showAnamnese(id);
      }
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    async showAnamnese(id) {
      this.$loading.start();
      await this.$http
        .show("anamnese/anamnese", id)
        .then((response) => {
          this.form = response.anamnese;
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    openAnamneseQuestionForm(question, index) {
      this.editIndex = index;
      this.$refs.AnamneseQuestionForm.open(question, index);
    },

    updateQuestion(data) {
      this.$set(this.form.questions, this.editIndex, data);
    },

    handleSave() {
      if (this.isInsert) {
        this.store();
      } else {
        this.update();
      }
    },

    store() {
      this.$loading.start();
      this.$http
        .store("anamnese/anamnese", this.form)
        .then((response) => {
          this.processSaved(response.anamnese);
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    update() {
      this.$loading.start();
      this.$http
        .update("anamnese/anamnese", this.form.id, this.form)
        .then((response) => {
          this.processSaved();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    processSaved(anamnese) {
      this.$loading.finish();
      this.$emit("store", anamnese);
      this.$router.push("/configuracoes/anamneses");
      // this.dialog = false;
    },
    removeQuestion(index) {
      this.$delete(this.form.questions, index);
    },
    async addQuestion(data) {
      await this.form.questions.push({
        type: "question",
        question: data.question,
        question_type: data.question_type,
        answer: data.answer,
        options: data.options,
      });
    },
    async addHeading(data) {
      await this.form.questions.push({
        type: "heading",
        heading: data.heading,
      });
    },

    openAnamneseHeadingForm(heading, index) {
      this.indexHeading = index;
      console.log(heading);

      this.$refs.AnamneseHeadingForm.open(heading, index);
    },
    updateHeading(data) {
      this.$set(this.form.questions, this.indexHeading, data);
    },
  },
};
</script>

<style scoped></style>
